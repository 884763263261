.panel { border:0px; }
.gm-style-iw{width:500px !important;top:15px !important;left:0px !important;background-color:#fff;box-shadow:0 1px 6px rgba(178, 178, 178, 0.6);border:1px solid rgba(72, 181, 233, 0.6);border-radius:2px 2px 10px 10px;}
#iw-container{margin-bottom:10px;}
#iw-container .iw-title{font-family:'Open Sans Condensed', sans-serif;font-size:22px;font-weight:400;padding:10px;background-color:#48b5e9;color:white;margin:0;border-radius:2px 2px 0 0;}
#iw-container .iw-content{font-size:13px;line-height:18px;font-weight:400;margin-right:1px;padding:15px 5px 20px 15px;max-height:240px;overflow-y:auto;overflow-x:hidden;}
.iw-content img{float:right;margin:0 5px 5px 10px;}
.iw-subTitle{font-size:16px;font-weight:700;padding:5px 0;}
.iw-bottom-gradient{position:absolute;width:326px;height:25px;bottom:10px;right:18px;background:linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);background:-webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);background:-moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);background:-ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);}
#map-canvas {
    width: 100%;
    height: 100%;
    min-height: 100%;
}
.control-sidebar-heading { padding: 10 10px; margin: 0; }
.toggle.ios, .toggle-on.ios, .toggle-off.ios { border-radius: 20px; float: right; }
.toggle.ios .toggle-handle { border-radius: 20px; }